
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const schema = Yup.object().shape({
      username: Yup.string().required().label("Username"),
      password: Yup.string().min(4).required().label("Password"),
    });

    return {
      store: useStore(),
      router: useRouter(),
      schema: schema,
    };
  },
  methods: {
    init() {
      console.log("init");
    },
    onSubmit(values) {
      this.store.dispatch(Actions.LOGOUT);
      (this.$refs.submitButton as HTMLElement|null).setAttribute("data-kt-indicator", "on");
      (this.$refs.submitButton as HTMLElement).classList.add("disabled");

      // Dummy delay
      setTimeout(() => {
        this.store
          .dispatch(Actions.LOGIN, {...values, system: "fas"})
          .then(() => {
            this.onVerify(values)
          })
          .catch(() => {

            const [error] = Object.keys(this.store.getters.getErrors);
            Swal.fire({
              text: this.store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        (this.$refs.submitButton as HTMLElement|null).removeAttribute("data-kt-indicator");
        (this.$refs.submitButton as HTMLElement).classList.remove("disabled");
      }, 2000);
    },

    async onVerify(values){
      const { value: password } = await Swal.fire({
            title: "One-Time Password (OTP)",
            text : "Please enter the One-Time Password (OTP) that was sent to your registered email address to verify your account.",
            input: "password",
            inputLabel: "Password",
            inputPlaceholder: "One-Time Password (OTP)",
            inputAttributes: {
              maxlength: 10,
              autocapitalize: "off",
              autocorrect: "off",
            },
          });

          if (password) {
            this.store
              .dispatch(Actions.TWOFA_LOGIN, {
                username: values.username,
                "2fa_token": password,
              })
              .then(() => {
                  this.router.push({ name: "dashboard" });
              }).catch( () => {
                const [error] = Object.keys(this.store.getters.getErrors);
                Swal.fire({
                  text: this.store.getters.getErrors[error],
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Try again!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                }).then(() => {
                  this.onVerify(values)
                });
              });
          }
          // });
    }
  },
  // setup() {
  //   const store = useStore();
  //   const router = useRouter();

  //   const submitButton = ref<HTMLButtonElement | null>(null);

  //   const loginData = {
  //     username: "",
  //     password: "",
  //     system: "fas",
  //   }

  //   //Create form validation object
  //   const login = Yup.object().shape({
  //     username: Yup.string().required().label("Username"),
  //     password: Yup.string().min(4).required().label("Password"),
  //   });

  //   //Form submit function
  //   const onSubmitLogin = (values) => {
  //     // Clear existing errors
  //     store.dispatch(Actions.LOGOUT);

  //     if (submitButton.value) {
  //       // eslint-disable-next-line
  //       submitButton.value!.disabled = true;
  //       // Activate indicator
  //       submitButton.value.setAttribute("data-kt-indicator", "on");
  //     }

  //     // Dummy delay
  //     setTimeout(() => {
  //       store
  //         .dispatch(Actions.LOGIN, loginData)
  //         .then(() => {
  //           Swal.fire({
  //             text: "You have successfully logged in!",
  //             icon: "success",
  //             buttonsStyling: false,
  //             confirmButtonText: "Ok, got it!",
  //             customClass: {
  //               confirmButton: "btn fw-bold btn-light-primary",
  //             },
  //           }).then(function () {
  //             // Go to page after successfully login
  //             router.push({ name: "dashboard" });
  //           });
  //         })
  //         .catch(() => {
  //           const [error] = Object.keys(store.getters.getErrors);
  //           Swal.fire({
  //             text: store.getters.getErrors[error],
  //             icon: "error",
  //             buttonsStyling: false,
  //             confirmButtonText: "Try again!",
  //             customClass: {
  //               confirmButton: "btn fw-bold btn-light-danger",
  //             },
  //           });
  //         });

  //       //Deactivate indicator
  //       submitButton.value?.removeAttribute("data-kt-indicator");
  //       // eslint-disable-next-line
  //       submitButton.value!.disabled = false;
  //     }, 2000);
  //   };

  //   return {
  //     onSubmitLogin,
  //     login,
  //     submitButton,
  //     loginData,
  //   };
  // },
});
